import $ from 'jquery';

//Check if element is in Viewport
$.fn.isInViewport = function () {
  var elementTop = $(this).offset().top;
  var elementBottom = elementTop + $(this).outerHeight();

  var viewportTop = $(window).scrollTop();
  var viewportBottom = viewportTop + $(window).height();

  return elementBottom > viewportTop && elementTop < viewportBottom;
};

$(function () {
  //Set header size for spesific screen width
  if (screen.width > 1070) {
    $(".header-wrapper").data("size", "big");
  }
});

$(window).on("resize", function () {
  //Set header size for spesific screen width
  if (screen.width > 1070) {
    $(".public-ui .header-wrapper").data("size", "big");
  }
});

$(window).on("scroll", (function () {
  //Check for animateFadeIn blocks if they are in viewport and animate them
  var blocksFadeIn = $(".animateFadeIn");
  $(blocksFadeIn).each(function(i, e) {
    if ($(e).isInViewport()) {
      $(e).animate({opacity: "1"}, 2500)
    };
  })

  //Check for animateMoveIn blocks if they are in viewport and animate them
  var blocks = $(".animateMoveIn")
  $(blocks).each(function(i, e) {
    if ($(e).isInViewport()) {
      $(e).find(".column-blocks-wrapper").even().animate({right: "0px"}, "slow");

      $(e).find(".column-blocks-wrapper").odd().animate({left: "0px"}, "slow");
    }
  })

  if (screen.width > 1070) {
    var imgHeight = $(":root").css("--imgHeight");
    var headerTop = $(":root").css("--headerTop");

    if ($(document).scrollTop() > 0) {
      if ($(".public-ui .header-wrapper").data("size") == "big") {
        $(".public-ui .header-wrapper").data("size", "small");
        $(".public-ui .header-wrapper").stop().animate({
          height: "5em",
          top: "0",
          "padding-top": "0",
          "padding-bottom": "0",
        }, 600);
        $(".public-ui .header-wrapper > .ui.container > .header > .logo-nav-wrapper > .logo > a > img").stop().animate({
          height: "5em",
        }, 600);
        $(".public-ui .bgImg").stop().animate({
          height: "0",
        }, 800);
        $(".public-ui #page-document").stop().animate({
          "padding-top": "0em",
        }, 600);
      }
    }
    else {
      if ($(".public-ui .header-wrapper").data("size") == "small") {
        $(".public-ui .header-wrapper").data("size", "big");
        $(".public-ui .bgImg").stop().animate({
          height: imgHeight,
        }, 600);
        $(".public-ui .header-wrapper").stop().animate({
          height: "8.5em",
          top: headerTop,
          "padding-top": "14px",
          "padding-bottom": "14px",
        }, 600);
        $(".public-ui .header-wrapper > .ui.container > .header > .logo-nav-wrapper > .logo > a > img").stop().animate({
          height: "6.5em",
        }, 600);
        $(".public-ui #page-document").stop().animate({
          "padding-top": "0",
        }, 600);
      }
    }
  }
}));

// Select the node that will be observed for mutations
const targetNode = document.getElementsByTagName("main")[0];

// Options for the observer (which mutations to observe)
const config = { attributes: true, childList: true, subtree: true };

// Callback function to execute when mutations are observed
const callback = (mutationList, observer) => {
  for (const mutation of mutationList) {
    if (mutation.type === "childList") {
      var blocksMoveIn = $(".animateMoveIn")
      $(blocksMoveIn).each(function(i, e) {
        if ($(e).isInViewport()) {
          $(e).find(".column-blocks-wrapper").even().animate({right: "0px"}, "slow");

          $(e).find(".column-blocks-wrapper").odd().animate({left: "0px"}, "slow");
        }
      })

      var blocksFadeIn = $(".animateFadeIn");
      $(blocksFadeIn).each(function(i, e) {
        if ($(e).isInViewport()) {
          $(e).animate({opacity: "1"}, 3000)
        };
      })
    }
  };
}

// Create an observer instance linked to the callback function
const observer = new MutationObserver(callback);

// Start observing the target node for configured mutations
observer.observe(targetNode, config);